import React, { PropsWithChildren } from "react";

type FlatProvidersProps = {
    providers: React.ReactElement[];
} & PropsWithChildren;

/*
FlatProviders takes all its children and nests them from first to last,
where first one will be the most outside the tree,
and the last one will be last in the tree.
*/

export const FlatProviders: React.FC<FlatProvidersProps> = props => {
    const { providers: p, children: init } = props;

    return <>{p.reduceRight((c, p) => React.cloneElement(p, { children: c }), init)}</>;
};
