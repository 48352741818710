import React from "react";

import { Header, HeaderProps } from "Atoms";
import { useLanguage } from "../../providers/languageProvider/LanguageProvider";

type Props = {
    tid: string;
} & HeaderProps;

export const LanguageHeader: React.FC<Props> = ({ tid, ...rest }) => {
    const { dictionary } = useLanguage();

    const text = dictionary[tid] || "";

    return <Header {...rest}>{text}</Header>;
};
